import "./Header.css";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {
    const [, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <nav class="navbar navbar-expand-lg navbar-dark navbar1 fixed-top" style={{padding:'0px'}}>
                <div class="container-fluid d-flex justify-content-" style={{backgroundColor: "brown",  backgroundSize: 'cover', backgroundPosition: 'center',padding:'0px' }}>
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item">
                            <a class="nav-link" href="www.google.com"><i class="fa-brands fa-facebook"></i></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="www.google.com"><i class="fa-brands fa-instagram"></i></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="www.google.com"><i class="fa-brands fa-whatsapp"></i></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="www.google.com">+91 89438489874 / 746786429</a>
                        </li>
                    </ul>
                </div>
            </nav>
            <nav class="navbar navbar-expand-lg sticky-top navbar-light shadow-sm" style={{ zIndex: '1',padding:'0px'}}>
        <div class="container" style={{backgroundColor:'#F6F2EA'}}>
        <a  className="navbar-brand d-block d-lg-none" href="www.google.com">
                            <img src="/logo.png" height="80" alt="car" />
                        </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse container-fluid" id="navbarNavDropdown" style={{padding:'0px !important'}}>
            <ul class="navbar-nav ms-auto">
            <li  className="nav-item">
                                    <Link to="/" className="nav-link mx-3 black-text" aria-current="page">Home</Link>
                                </li>
              <li  className="nav-item dropdown">
                                    <Link to="/almonds" className="nav-link mx-2 dropdown-toggle black-text" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Dry Fruits
                                    </Link>
                                    <ul  className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link to="/Almonds" className="dropdown-item">Almonds</Link></li>
                                        <li><Link to="/Pistachio"   className="dropdown-item" >Pistachio</Link></li>
                                        <li><Link to="/Cashew"  className="dropdown-item" >Cashew</Link></li>
                                        <li><Link to="/Walnuts"  className="dropdown-item">Walnuts</Link></li>
                                        <li><Link to="/Raisins"  className="dropdown-item">Raisins</Link></li>
                                    </ul>
                                </li>
                                <li  className="nav-item dropdown">
                                    <Link to="/almonds" className="nav-link mx-2 dropdown-toggle black-text" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Seeds & Berries
                                    </Link>
                                    <ul  className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link to="/Seeds" className="dropdown-item">Seeds</Link></li>
                                        <li><Link to="/Berries"   className="dropdown-item" >Berries</Link></li>
                                        <li><Link to="/Mix-Seeds"  className="dropdown-item" >Mix Seeds</Link></li>
                                    </ul>
                                </li>
            
              <li class="nav-item">
                <a class="nav-link mx-2 text-uppercase" href="www.google.com">
                  About Us
                </a>
              </li>
            </ul>
          </div>
        </div>   
      </nav>
                {/* <nav  className="navbar navbar-expand-lg navbar-dark p-3 wrapper navbar2" id="headerNav" style={{backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div  className="container-fluid">
                        <a  className="navbar-brand d-block d-lg-none" href="www.google.com">
                            <img src="/logo.png" height="80" alt="car" />
                        </a>
                        <button className="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" style={{ position: 'relative' , color: 'black !important', borderColor: 'black'  }}>
                            <span  className="navbar-toggler-icon"></span>
                        </button>

                        <div  className=" collapse navbar-collapse" id="navbarNavDropdown">
                            <ul  className="navbar-nav mx-auto nav-options">
                                <li  className="nav-item">
                                    <Link to="/" className="nav-link mx-3 black-text" aria-current="page">Home</Link>
                                </li>
                                <li  className="nav-item dropdown">
                                    <Link to="/almonds" className="nav-link mx-2 dropdown-toggle black-text" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Dry Fruits
                                    </Link>
                                    <ul  className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link to="/Almonds" className="dropdown-item">Almonds</Link></li>
                                        <li><Link to="/Pistachio"   className="dropdown-item" >Pistachio</Link></li>
                                        <li><Link to="/Cashew"  className="dropdown-item" >Cashew</Link></li>
                                        <li><Link to="/Walnuts"  className="dropdown-item">Walnuts</Link></li>
                                        <li><Link to="/Raisins"  className="dropdown-item">Raisins</Link></li>
                                    </ul>
                                </li>
                                <li  className="nav-item dropdown">
                                    <Link to="/almonds" className="nav-link mx-2 dropdown-toggle black-text" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Seeds & Berries
                                    </Link>
                                    <ul  className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link to="/Seeds" className="dropdown-item">Seeds</Link></li>
                                        <li><Link to="/Berries"   className="dropdown-item" >Berries</Link></li>
                                        <li><Link to="/Mix-Seeds"  className="dropdown-item" >Mix Seeds</Link></li>
                                    </ul>
                                </li>
                                <li  className="nav-item d-none d-lg-block">
                                    <a  className="nav-link mx-2" href="www.google.com">
                                        <img src="/logo.png" height="80" alt="car"/>
                                    </a>
                                </li>
                                <li  className="nav-item">
                                    <Link to="/About-Us" className="nav-link mx-3 black-text">About Us</Link>
                                </li>
                                <li  className="nav-item">
                                    <a  className="nav-link mx-3 black-text" href="www.google.com">Blogs</a>
                                </li>
                                <li  className="nav-item">
                                    <Link to="/Contact-Us"  className="nav-link mx-3 black-text">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav> */}
        </>
    );
}

export default Header;
