import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer style={{ backgroundColor: '#deded5', width: '100%', position: 'relative', zIndex: 1 }}>
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-6 col-md-12 mb-4">
            <h5 className="mb-3" style={{ letterSpacing: '2px', color: '#818963' }}>GOYAL DRY-FRUITS</h5>
            <p>
            At GOYAL DRY-FRUITS, we are dedicated to providing you with the finest quality dry fruits sourced from around the world. Our commitment to excellence ensures that every product you receive is of the highest standard, packed with natural goodness and flavor.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <h5 className="mb-3" style={{ letterSpacing: '2px', color: '#818963' }}>links</h5>
            <ul className="list-unstyled mb-0">
              <li className="mb-1">
                <a href="#!" style={{ color: '#4f4f4f' }}>Frequently Asked Questions</a>
              </li>
              <li className="mb-1">
                <a href="#!" style={{ color: '#4f4f4f' }}>Delivery</a>
              </li>
              <li className="mb-1">
                <a href="#!" style={{ color: '#4f4f4f' }}>Pricing</a>
              </li>
              <li>
                <a href="#!" style={{ color: '#4f4f4f' }}>Where we deliver?</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 mb-4">
            <h5 className="mb-1" style={{ letterSpacing: '2px', color: '#818963' }}>opening hours</h5>
            <table className="table" style={{ color: '#4f4f4f', borderColor: '#666' }}>
              <tbody>
                <tr>
                  <td>Mon - Fri:</td>
                  <td>8am - 9pm</td>
                </tr>
                <tr>
                  <td>Sat - Sun:</td>
                  <td>8am - 1am</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', width: '100%' }}>
        
        <p className="text-dark">© 2020 Copyright : GOYAL DRY-FRUITS</p>
      </div>
    </footer>
  );
}

export default Footer;


