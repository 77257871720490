import './App.css';
import Home from './Home';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import FeaturedCollection from './FeaturedCollection'


function HomePage() {
  
  return (
    <div className="App">
      <>
        <FloatingWhatsApp
          phoneNumber="8076255880" // Replace with your WhatsApp phone number
          accountName="Sonal" // Replace with your WhatsApp account name
        />
        <Home />
        <FeaturedCollection />
      </>
  </div>
  );
}

export default HomePage;