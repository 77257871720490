import React from 'react';
import './Featured-Collection.css';

// function ProductCard({ imageUrl, heading, price }) {

//   return (
//     <div className="col-6 col-md-3 col-lg-3 mb-4 box-with-shadow" style={{ padding: "10px" }}>
//       <div className="card h-100">
//         <img className="card-img-top" src={imageUrl} alt={heading} style={{ height: '300px', width: 'auto'}} />
//         <div className="card-body">
//           <h4 className="card-title">{heading}</h4>
//           <h5 style={{ color: "blue" }}>From Rs. {price}</h5>
//         </div>
//       </div>
//     </div>
//   );
// }

const ProductCard = ({ imageUrl, heading, price }) => {
  const isMobile = window.innerWidth <= 767;

  return (
    <>
      <style>
        {`
          @media (max-width: 767px) {
            .product-card-img {
              height: 150px !important;
            }
            .product-card-title {
              font-size: 1rem !important; /* Equivalent to h6 */
            }
          }
        `}
      </style>
      <div className="col-6 col-md-3 col-lg-3 mb-4 box-with-shadow" style={{ padding: '10px', marginTop: isMobile ? '10px' : '0px' }}>
        <div className="card h-100">
          <img
            className="card-img-top product-card-img"
            src={imageUrl}
            alt={heading}
            style={{
              height: isMobile ? '150px' : '300px',
              width: '100%',
            }}
          />
          <div className="card-body">
            {isMobile ? (
              <h6 className="card-title product-card-title">{heading}</h6>
            ) : (
              <h4 className="card-title product-card-title">{heading}</h4>
            )}
            <h5 style={{ color: 'blue' }}>From Rs. {price}</h5>
          </div>
        </div>
      </div>
    </>
  );
};

function FeaturedCollection() {
  // Sample data for 12 products
  const products = [
    { imageUrl: '/products/almonds california.jpg', heading: 'American Almonds', price: 760 },
    { imageUrl: '/products/Breakfast 2.jpg', heading: 'Breakfast', price: 800 },
        { imageUrl: '/products/Breakfast.jpg', heading: 'Mix Seeds', price: 500 },
        { imageUrl: '/products/Gurbandi Giri.jpg', heading: 'Gurbandi Giri Almonds', price: 780 },
        { imageUrl: '/products/pistachio 1.jpg', heading: 'Pistacahio', price: 1050 },
        { imageUrl: '/products/akhrot 1.jpg', heading: 'Premium Walnut  (Akhrot)', price: 1300 },
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          {products.map((product, index) => (
            <ProductCard
              key={index}
              imageUrl={product.imageUrl}
              heading={product.heading}
              price={product.price}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default FeaturedCollection;


  // Function to handle adding to cart
  // const handleAddToCart = () => {
  //   // Implement your add to cart logic here
  //   console.log('Added to cart:', heading);
  // };

  // // Function to handle adding to wishlist
  // const handleAddToWishlist = () => {
  //   // Implement your add to wishlist logic here
  //   console.log('Added to wishlist:', heading);
  // };