import React from 'react';
import './Home.css';
import { useState, useEffect } from 'react';

const Card = ({ imageUrl, heading, price }) => {
  const isMobile = window.innerWidth <= 767;

  return (
    <>
      <style>
        {`
          @media (max-width: 767px) {
            .product-card-img {
              height: 150px !important;
            }
            .product-card-title {
              font-size: 1rem !important; /* Equivalent to h6 */
            }
          }
        `}
      </style>
      <div className="col-6 col-md-3 col-lg-3 mb-4 box-with-shadow" style={{ padding: '10px', marginTop: isMobile ? '10px' : '0px' }}>
        <div className="card h-100">
          <img
            className="card-img-top product-card-img"
            src={imageUrl}
            alt={heading}
            style={{
              height: isMobile ? '150px' : '300px',
              width: '100%',
            }}
          />
          <div className="card-body">
            {isMobile ? (
              <h6 className="card-title product-card-title">{heading}</h6>
            ) : (
              <h4 className="card-title product-card-title">{heading}</h4>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function Home() {

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
      const interval = setInterval(() => {
          setActiveIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
      }, 4000);

      return () => clearInterval(interval);
  }, []);

  const images = [
      { src: './almonds.jpg', heading: 'ALMONDS - King of Nuts', text: 'For a Healthy Self' },
      { src: './cashew.jpg', heading: 'CASHEW - A Actual Seed', text: 'Best Among the Rest' },
      { src: './pistachio.jpg', heading: 'PISTACHIO - The Happy Nut', text: 'Go Healthy and Happy' }
  ];

  // const Card = ({ title, image }) => {
  //   const isMobile = window.innerWidth <= 767;
  
  //   return (
  //     <>
  //       <div className="card product-card-img" style={{ padding: '10px' }}>
  //         <img
  //           src={image}
  //           className="card-img-top"
  //           alt={title}
  //           style={{
  //             height: isMobile ? '150px' : '350px',
  //             objectFit: 'cover',
  //             width: isMobile ? '200px' : '100%',
  //           }}
  //         />
  //         <div className="card-body">
  //           {isMobile ? (
  //             <h6 className="card-title text-center">{title}</h6>
  //           ) : (
  //             <h5 className="card-title text-center">{title}</h5>
  //           )}
  //         </div>
  //       </div>
  //     </>
  //   );
  // };  

  const products = [
    { imageUrl: 'dates.jpg', heading: 'Dates' },
    { imageUrl: 'dryfruits.jpg', heading: 'Dry Fruits' },
    { imageUrl: 'nuts.jpg', heading: 'Nuts and More' },
    { imageUrl: 'seeds.jpg', heading: 'Seeds'},
];

  return (
<>
<div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {images.map((image, index) => (
                        <div key={index} className={`carousel-item${index === activeIndex ? ' active' : ''}`} style={{ position: 'relative' }}>
                            <img
                                src={image.src}
                                style={{ height: '500px', width: '100%', transition: 'transform 3s', transform: index === activeIndex ? 'scale(1.1)' : 'scale(1)' }}
                                className="d-block w-100"
                                alt={`Slide ${index}`}
                            />
                            <div className="carousel-overlay"></div>
                            <div className="carousel-caption centered-caption" style={{ position: 'absolute', top: '50%', right: '0', transform: 'translateY(-50%)', zIndex: 1, opacity: index === activeIndex ? '1' : '0', transition: 'opacity 1s, right 1s' }}>
                                <h2 style={{ fontSize: '3.5rem', color: 'white', transition: 'opacity 1s' , fontFamily: 'Brush Script' }}>{image.heading}</h2>
                                <p style={{ fontSize: '2.2rem', color: 'white', fontStyle: 'italic', transition: 'opacity 1s' }}>{image.text}</p>
                                <button style={{ borderRadius: '10%', backgroundColor: 'transparent', border: '0.5px solid white', color: 'white', padding: '10px 20px', cursor: 'pointer', fontSize: '1rem', transition: 'opacity 1s' }}>Buy Now</button>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev" onClick={() => setActiveIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1))}>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next" onClick={() => setActiveIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1))}>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

<div className='container banner'>

<div className='container'>
<div className="mt-5 future">
           <h1 className="future-head" style={{color:"brown"}}>CATEGORIES</h1>
           <div className="underline" style={{color:"brown"}}></div>
        </div>
  </div>

  {/* <div className='container'>
    <div className="mt-5 future">
           
      <div className="row">
        <div className="col-6 col-md-3">
          <Card title="Dry Fruits" image="./dryfruits.jpg" />
        </div>
        <div className="col-6 col-md-3">
          <Card title="SEEDS & BERRIES" image="./seeds.jpg" />
        </div>
        <div className="col-6 col-md-3 after-2-cards">
          <Card title="Roasted DRY-FRUITS" image="./dates.jpg" />
        </div>
        <div className="col-6 col-md-3 after-2-cards">
          <Card title="Dates" image="./nuts.jpg" />
        </div>
      </div>
    </div>
 </div> */}
 <div className='container'>
                <div className="row">
                    {products.map((product, index) => (
                        <Card
                            key={index}
                            imageUrl={product.imageUrl}
                            heading={product.heading}
                        />
                    ))}
                </div>
            </div>
</div>

  <img className='container-fluid img-after-cat' src={process.env.PUBLIC_URL + '/center-pic.jpg'} alt="center-pic" style={{width:"100%" , marginTop : "50px"}}  />
  {/* <div class="parallax-container ">
        <div class="parallax-image">
          
        </div>
      </div> */}


  <div className='container'>
        <div className="mt-5 future">
          <h1 className="future-head" style={{ color: "brown" }}>Top Selected Products</h1>
          <div className="underline" style={{ color: "brown" }}></div>
        </div>
      </div>
{/* <div className='container'>
    <div className="row">
      <div className="col-md-3">
        <div className="card border-0">
          <img src="dryfruits.png" className="card-img-top" alt="Image 1" />
          <div className="card-body">
            <h5 className="card-title">Dry Fruits</h5>
            <p className="card-text">Rs. 550</p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card border-0">
          <img src="dates.png" className="card-img-top" alt="Image 2" />
          <div className="card-body">
            <h5 className="card-title">Dates</h5>
            <p className="card-text">Rs. 400</p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card border-0">
          <img src="seeds.png" className="card-img-top" alt="Image 3" />
          <div className="card-body">
            <h5 className="card-title">Seeds</h5>
            <p className="card-text">Rs. 440</p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card border-0">
          <img src="nuts.png" className="card-img-top" alt="Image 4" />
          <div className="card-body">
            <h5 className="card-title">Nuts</h5>
            <p className="card-text">Rs. 250</p>
          </div>
        </div>
      </div>
    </div>
  </div> */}
</>
  );
}

export default Home;
