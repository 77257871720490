import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faClipboardCheck, faCog, faPhone } from '@fortawesome/free-solid-svg-icons';

const AboutUs = () => {
  return (
    <>
    <div>
    <div className='container'>
        <div className="mt-5 future">
          <h1 className="future-head" style={{ color: "brown" }}>About Us</h1>
          <div className="underline" style={{ color: "brown" }}></div>
        </div>
        <div className='container card' style={{border:"2px solid grey"}}>
        <div className="mt-5 future">
          <h2 className="future-head" >WHO WE ARE ....</h2>
          <div className="underline" style={{ color: "black" }}></div>
        </div>
        <p style={{marginTop : "15px"}}>
        For over five decades, Goyal Dry Fruits has been dedicated to providing premium dried fruits that embody our commitment to excellence. Our journey began in 1968 with a vision to offer the finest and freshest dry fruits to our customers.
With a rich legacy that spans generations, Goyal Dry Fruits has stood the test of time by upholding the values of tradition, quality, and customer satisfaction. Since our inception, we have been a beacon of reliability and taste in the dry fruit industry.
Our products, sourced from the best orchards around the world, are meticulously selected to ensure superior quality and freshness. Each bite of our carefully curated selection reflects our passion for bringing you the best that nature has to offer.
At Goyal Dry Fruits, we believe in building lasting relationships with our customers, rooted in trust and authenticity. As a family-owned business, we take pride in serving you with the same dedication and care that has been our hallmark since 1968.
Join us in celebrating over 50 years of excellence in dry fruits. Experience the taste of tradition and quality with Goyal Dry Fruits
—a name you can trust since 1968. Thank you for being a part of our enduring journey.
        </p>
        </div>

        
      <div className="row" style={{marginTop : "40px"}}>
        <div className="col-md-3 yellow-hover">
          <div className="card" style={{border:"2px solid grey"}}>
            <div className="card-body text-center">
              <FontAwesomeIcon icon={faUsers} size="3x" style={{ color: "brown" }}/>
              <h5 className="card-title" style={{marginTop : "10px"}}>Customer Support</h5>
              <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 yellow-hover">
          <div className="card" style={{border:"2px solid grey"}}>
            <div className="card-body text-center">
              <FontAwesomeIcon icon={faClipboardCheck} size="3x" style={{ color: "brown" }}/>
              <h5 className="card-title" style={{marginTop : "10px"}}>Quality Assurance</h5>
              <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 yellow-hover">
          <div className="card" style={{border:"2px solid grey"}}>
            <div className="card-body text-center">
              <FontAwesomeIcon icon={faCog} size="3x" style={{ color: "brown" }}/>
              <h5 className="card-title" style={{marginTop : "10px"}}>Technical Support</h5>
              <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 yellow-hover">
          <div className="card" style={{border:"2px solid grey"}}>
            <div className="card-body text-center">
              <FontAwesomeIcon icon={faPhone} size="3x" style={{ color: "brown" }}/>
              <h5 className="card-title" style={{marginTop : "10px"}}>24/7 Helpline</h5>
              <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
        </div>
      </div>

      <div className='container card' style={{border:"2px solid grey"}}>
      <div className="mt-5 future">
          <h2 className="future-head" > Meat the Masters of Dry Fruits : Crafting Health and Flavours</h2>
          <div className="underline" style={{ color: "black" }}></div>
        </div>
      <p style={{marginTop : "15px"}}>
      At Goyal Dry Fruits, we are passionate about providing high-quality, premium dry fruits to our customers. Our journey began with a simple mission: to offer a wide variety of dried fruits that are not only delicious but also nutritious.
We source the finest fruits from trusted suppliers and ensure that each product meets our strict quality standards. Whether you're looking for a healthy snack, a special gift, or ingredients for your culinary creations, Goyal Dry Fruits has you covered.
Our commitment to customer satisfaction drives everything we do. We strive to offer a seamless shopping experience, prompt delivery, and excellent customer service.
Explore our selection of almonds, cashews, pistachios, raisins, and more. Join us in celebrating the natural goodness of dry fruits and the joy they bring to every occasion. Thank you for choosing Goyal Dry Fruits.
      </p>
      </div>
      </div>
    </div>
      
    </>
  );
};

export default AboutUs;