import React from 'react';

const ContactUs = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding: '20px' }}>
      {/* Left side with map */}
      <div style={{ width: '30%', height: '300px' }}>
        <img style={{ width: '170%', height: '400px' }} src='./ChandaniChowk.png' alt='map' />
      </div>
s
      {/* Right side with information */}
      <div style={{ width: '45%', paddingLeft: '20px' }}>
        <h2>Goyal Dry - Fruits</h2>
        <p>Established in 1991, Ojas Petrochem Company is the leading supplier of lubricant oils and greases for industrial use. We provide products for various industries such as automotive, marine (e.g., shipbuilding), construction industry, mining industry, and others.</p>
        <h3>Address</h3>
        <p>WZ-14B, Manohar Park, East Punjabi Bagh, Near Ashoka Park Metro Station, New Delhi 110026, INDIA</p>
        <h3>Business Hours</h3>
        <p>Monday-Saturday: 10am - 7pm</p>
        <p>Sunday: Closed</p>
      </div>
    </div>
  );
};

export default ContactUs;