import React from 'react';
import './AlmondsComponent.css';

const ProductCard = ({ imageUrl, heading, price }) => {
    return (
      <div className="col-6 col-md-3 col-lg-3 mb-4 box-with-shadow" style={{ padding: "10px" }}>
        <div className="card h-100">
          <img 
            className="card-img-top product-card-img" 
            src={imageUrl} 
            alt={heading} 
            style={{ height: '250px', width: '100%' }} 
          />
          <div className="card-body">
            <h4 className="card-title product-card-title">{heading}</h4>
            <h5 className='product-card-title' style={{ color: "blue" }}>From Rs. {price}</h5>
          </div>
        </div>
      </div>
    );
  }

const AlmondsComponent = () => {
    // Sample data for Almonds products
    const products = [
        { imageUrl: '/products/almond sanora.jpg', heading: 'Sanora Almonds', price: 1050 },
        { imageUrl: '/products/almonds california.jpg', heading: 'American Almonds', price: 760 },
        { imageUrl: '/products/Mamra 2.jpg', heading: 'Mamra Almonds', price: 2500 },
        { imageUrl: '/products/Gurbandi Giri.jpg', heading: 'Gurbandi Giri Almonds', price: 780 },
    ];

    return (
        <div>
                 <div className='container'>
        <div className="mt-5 future">
          <h1 className="future-head" style={{ color: "brown" }}>Almonds</h1>
          <div className="underline" style={{ color: "brown" }}></div>
        </div>
      </div>
            <div className='container'>
                <div className="row">
                    {products.map((product, index) => (
                        <ProductCard
                            key={index}
                            imageUrl={product.imageUrl}
                            heading={product.heading}
                            price={product.price}
                        />
                    ))}
                </div>
            </div>
            <div className='container'>
            <h1 className="future-head" style={{ color: "Black" }}>Why Almonds are essential </h1>
            <p>Almonds are nature's treasure trove, packed with essential nutrients like protein, fiber, vitamin E, magnesium, and healthy fats. Incorporating almonds into your diet can help fuel your body with the goodness it needs to thrive.
Almonds have been shown to promote heart health by helping to lower bad cholesterol levels and reduce the risk of heart disease. By enjoying almonds as part of your daily routine, you're making a heart-smart choice that your body will thank you for.
Contrary to popular belief, almonds can actually support your weight management goals. Their combination of protein, fiber, and healthy fats helps keep you feeling fuller for longer, making it easier to resist unhealthy snacks and stick to your wellness journey.
If you're looking to maintain stable blood sugar levels, almonds are a fantastic option. With their low glycemic index, they provide sustained energy without causing spikes in blood sugar, making them a smart choice for individuals with diabetes or those watching their sugar intake.
Your brain deserves some love too! Almonds contain nutrients like vitamin E and antioxidants that have been linked to improved cognitive function and brain health.</p><p> So, next time you need a study snack or a mid-afternoon pick-me-up, reach for some almonds and give your brain a boost.
Whether you enjoy them straight out of the bag, sprinkled on top of salads, blended into smoothies, or baked into tasty treats, almonds are incredibly versatile and delicious. With so many ways to enjoy them, there's no excuse not to make almonds a regular part of your snacking repertoire.
We're committed to sourcing only the finest almonds for our customers. Our almonds are carefully selected for their superior quality and taste, so you can trust that you're getting the best of the best every time you shop with us.</p>
            
            </div>
        </div>
    );
}


const PistcahioComponent = ({ imageUrl, heading, price }) => {

    const products = [
        { imageUrl: '/products/pistachio 1.jpg', heading: 'Pistacahio', price: 1050 },
        { imageUrl: '/products/pistachio 2.jpg', heading: 'Premium Pistacahio', price: 1150 },
        { imageUrl: '/products/pistachio green.jpg', heading: 'Green Pistacahio (Unshelled)', price: 1300 },

    ];

    return (
        <div>
             <div className='container'>
        <div className="mt-5 future">
          <h1 className="future-head" style={{ color: "brown" }}>Pistachio</h1>
          <div className="underline" style={{ color: "brown" }}></div>
        </div>
      </div>
            <div className="container">
                <div className="row">
                    {products.map((product, index) => (
                        <ProductCard
                            key={index}
                            imageUrl={product.imageUrl}
                            heading={product.heading}
                            price={product.price}
                        />
                    ))}
                </div>
            </div>
            <div className='container'>
            <h1 className="future-head" style={{ color: "Black" }}>Why Pistachio are essential </h1>
            <p>You can buy the best organic almonds online hassle-free considering that they are the powerhouse of nutrients. They are deliciously satisfying no matter if you consume them whole, sliced, chopped, or add them to your food as a paste. Almonds are a trusted and essential source of antioxidants that are concentrated in the brown layer. You must know that those who consume about 2.5 ounces of almonds regularly, experience decreased oxidative stress by 27% in a month.</p>
            <p>You can buy the best organic almonds online hassle-free considering that they are the powerhouse of nutrients. They are deliciously satisfying no matter if you consume them whole, sliced, chopped, or add them to your food as a paste. Almonds are a trusted and essential source of antioxidants that are concentrated in the brown layer. You must know that those who consume about 2.5 ounces of almonds regularly, experience decreased oxidative stress by 27% in a month.</p>
            </div>
            
        </div>
    );
}

const CashewComponent = ({ imageUrl, heading, price }) => {
    const products = [
        { imageUrl: '/products/cashew 2.jpg', heading: 'Cashew 320', price: 1050 },
        { imageUrl: '/products/cashew 1.jpg', heading: 'Cashew 240', price: 1050 },
        { imageUrl: '/products/cashew 3.jpg', heading: 'Cashew 210', price: 1050 },
        { imageUrl: '/products/cashew 1.jpg', heading: 'Cashew 180', price: 1050 },
    ];

    return (
        <div>
             <div className='container'>
        <div className="mt-5 future">
          <h1 className="future-head" style={{ color: "brown" }}>CASHEW</h1>
          <div className="underline" style={{ color: "brown" }}></div>
        </div>
      </div>
            <div className="container">
                <div className="row">
                    {products.map((product, index) => (
                        <ProductCard
                            key={index}
                            imageUrl={product.imageUrl}
                            heading={product.heading}
                            price={product.price}
                        />
                    ))}
                </div>
            </div>
            <div className='container'>
            <h1 className="future-head" style={{ color: "Black" }}>Why CASHEW are essential </h1>
            <p>You can buy the best organic almonds online hassle-free considering that they are the powerhouse of nutrients. They are deliciously satisfying no matter if you consume them whole, sliced, chopped, or add them to your food as a paste. Almonds are a trusted and essential source of antioxidants that are concentrated in the brown layer. You must know that those who consume about 2.5 ounces of almonds regularly, experience decreased oxidative stress by 27% in a month.</p>
            <p>You can buy the best organic almonds online hassle-free considering that they are the powerhouse of nutrients. They are deliciously satisfying no matter if you consume them whole, sliced, chopped, or add them to your food as a paste. Almonds are a trusted and essential source of antioxidants that are concentrated in the brown layer. You must know that those who consume about 2.5 ounces of almonds regularly, experience decreased oxidative stress by 27% in a month.</p>
            </div>
            
        </div>
    );
}

const WalnutsComponent = ({ imageUrl, heading, price }) => {
    const products = [
        { imageUrl: '/products/akhrot 1.jpg', heading: 'Premium Walnut  (Akhrot)', price: 1300 },
        { imageUrl: '/products/akhrot 2.jpg', heading: 'Premium Walnut  (Akhrot)', price: 1050 },
    ];

    return (
        <div>
             <div className='container'>
        <div className="mt-5 future">
          <h1 className="future-head" style={{ color: "brown" }}>Wallnuts</h1>
          <div className="underline" style={{ color: "brown" }}></div>
        </div>
      </div>
            <div className="container">
                <div className="row">
                    {products.map((product, index) => (
                        <ProductCard
                            key={index}
                            imageUrl={product.imageUrl}
                            heading={product.heading}
                            price={product.price}
                        />
                    ))}
                </div>
            </div>
            <div className='container'>
            <h1 className="future-head" style={{ color: "Black" }}>Why Wallnuts are essential </h1>
            <p>You can buy the best organic almonds online hassle-free considering that they are the powerhouse of nutrients. They are deliciously satisfying no matter if you consume them whole, sliced, chopped, or add them to your food as a paste. Almonds are a trusted and essential source of antioxidants that are concentrated in the brown layer. You must know that those who consume about 2.5 ounces of almonds regularly, experience decreased oxidative stress by 27% in a month.</p>
            <p>You can buy the best organic almonds online hassle-free considering that they are the powerhouse of nutrients. They are deliciously satisfying no matter if you consume them whole, sliced, chopped, or add them to your food as a paste. Almonds are a trusted and essential source of antioxidants that are concentrated in the brown layer. You must know that those who consume about 2.5 ounces of almonds regularly, experience decreased oxidative stress by 27% in a month.</p>
            </div>
        </div>
    );
}

const RaisinsComponent = ({ imageUrl, heading, price }) => {
    const products = [
        { imageUrl: '/products/akhrot 2.jpg', heading: 'Premiumn Raisins', price: 1050 },
        { imageUrl: '/products/akhrot 2.jpg', heading: 'Raisins', price: 1050 },
        
    ];

    return (
        <div>
             <div className='container'>
        <div className="mt-5 future">
          <h1 className="future-head" style={{ color: "brown" }}>Raisins</h1>
          <div className="underline" style={{ color: "brown" }}></div>
        </div>
      </div>
            <div className="container">
                <div className="row">
                    {products.map((product, index) => (
                        <ProductCard
                            key={index}
                            imageUrl={product.imageUrl}
                            heading={product.heading}
                            price={product.price}
                        />
                    ))}
                </div>
            </div>
            <div className='container'>
            <h1 className="future-head" style={{ color: "Black" }}>Why Raisins are essential </h1>
            <p>You can buy the best organic almonds online hassle-free considering that they are the powerhouse of nutrients. They are deliciously satisfying no matter if you consume them whole, sliced, chopped, or add them to your food as a paste. Almonds are a trusted and essential source of antioxidants that are concentrated in the brown layer. You must know that those who consume about 2.5 ounces of almonds regularly, experience decreased oxidative stress by 27% in a month.</p>
            <p>You can buy the best organic almonds online hassle-free considering that they are the powerhouse of nutrients. They are deliciously satisfying no matter if you consume them whole, sliced, chopped, or add them to your food as a paste. Almonds are a trusted and essential source of antioxidants that are concentrated in the brown layer. You must know that those who consume about 2.5 ounces of almonds regularly, experience decreased oxidative stress by 27% in a month.</p>
            </div>
        </div>
    );
}

// export default AlmondsComponent;
export { AlmondsComponent, CashewComponent, WalnutsComponent, RaisinsComponent , PistcahioComponent };