import './App.css';
import Header from './Header';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Footer from './Footer';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { AlmondsComponent, CashewComponent, WalnutsComponent, RaisinsComponent , PistcahioComponent } from "./AlmondsComponent";
import HomePage from './HomePage';
import { SeedsComponent, BerriesComponent, MixSeedsComponent} from './SeedsAndBerries';
import ContactUs from './ContactUs';
import AboutUs from './AboutUs';

function App() {
  
  return (
    <div className="App">
    <Router>
      <>
        <FloatingWhatsApp
          phoneNumber="8076255880" // Replace with your WhatsApp phone number
          accountName="Sonal" // Replace with your WhatsApp account name
        />
        <Header />
        <Routes>
        <Route path="/" element={<HomePage />} />
         {/* Dryfruits */}
          <Route path="/Almonds" element={<AlmondsComponent />} />
          <Route path="/Cashew" element={<CashewComponent />} />
          <Route path="/Walnuts" element={<WalnutsComponent />} />
          <Route path="/Raisins" element={<RaisinsComponent />} />
          <Route path="/Pistachio" element={<PistcahioComponent />} />
          {/* Seeds and Berries Routes */}
          <Route path="/Seeds" element={<SeedsComponent />} />
          <Route path="/Berries" element={<BerriesComponent />} />
          <Route path="/Mix-Seeds" element={<MixSeedsComponent />} />
          <Route path="/Contact-Us" element={<ContactUs />} />
          <Route path="/About-Us" element={<AboutUs />} />
        </Routes>
         <Footer />
      </>
    </Router>
  </div>
  );
}

export default App;
